import { Component } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { log } from 'console'
import { AuthService } from 'src/app/auth.service'

@Component({
  selector: 'cui-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  showStoreSelect: boolean = true
  storeid: number = 0
  SId: number = 0
  userId: number = 0
  companyid: number = 0
  stores: any = []
  showStore: boolean = true
  roleId = 0

  // user_pages = [
  //   // '/apps/internaltransfer',
  //   '/apps/ReceiveOrders',
  //   '/apps/dispatch',
  //  ]

  erp_pages = [
    '/apps/internaltransfer',
    '/apps/ReceiveOrders',
    '/apps/dispatch',
    '/apps/dispatch-index',
    '/apps/purchaseentry',
    '/apps/billspaybyvendor',
    '/apps/sendtostore',
    '/apps/initalstock',
    '/apps/stockreorder',
    '/apps/stockupdate',
    '/apps/autoorder',
    '/apps/credit',
    '/apps/StoreProduct',
    '/apps/dayclosing',
    '/apps/purchmaint',
    '/apps/NewToOrder',
    '/apps/ToOrder',
    '/apps/ToReceive',
    '/apps/ReceivedOrder',
    '/apps/NewToDispatch',
    '/apps/ToDispatch',
    '/apps/DispatchedOrder',
    '/apps/moneytransfer',
    '/apps/deposit',
    '/apps/maintanance',
    '/apps/cashclosing',
    '/apps/accountclosing',
    '/apps/accountclosing',
    '/apps/assettype',
  ]

  savestore: any
  is_user_page: boolean = false
  is_erp_page: boolean = false

  constructor(private auth: AuthService, public router: Router) {
    const user = JSON.parse(localStorage.getItem('user'))
    console.log(user)
    this.roleId = user[0].roleId
    this.SId = user[0].storeId
    this.userId = user[0].userId
    console.log(this.userId)
    this.savestore = user[0].store
    const company = JSON.parse(localStorage.getItem('companyId'))
    console.log('CompanyLocal', company)
    this.companyid = company[0].companyId
    console.log(this.companyid)

    router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        console.log(event['url'])
        if (
          this.erp_pages.includes(event['url']) &&
          (user[0].roleId == 1 ||
            user[0].roleId == 2 ||
            user[0].roleId == 8 ||
            user[0].roleId == 9 ||
            user[0].roleId == 7)
        ) {
          this.is_erp_page = true
          this.is_user_page = false
        } else {
          this.is_erp_page = false
          this.is_user_page = true
        }
      }
    })
  }

  ngOnInit(): void {
    this.auth.storeid.subscribe(x => {
      this.storeid = Number(x)
      console.log(this.storeid)
    })

    this.getStores()

    let storeid = +(localStorage.getItem('StoredStoredId') || '0')
    let contactid = +(localStorage.getItem('StoredContactId') || '0')
    this.auth.storeid.next(storeid)
    this.auth.contactid.next(contactid)
    console.log(this.contactid)
  }

  contactid: any

  setstoreid() {
    this.auth.storeid.next(this.storeid)
    console.log(this.stores)
    this.contactid = this.stores.filter(x => x.id == this.storeid)[0]?.contactId
    this.auth.contactid.next(this.contactid)

    console.log(this.contactid)

    localStorage.setItem('StoredStoredId', this.storeid.toString())
    // console.log(StoredStoredId)
    // this.storeid = +StoredStoredId
    console.log(this.storeid)
    localStorage.setItem('StoredContactId', this.contactid)
    // this.contactid = +StoredContactId
    // this.contactid = this.auth.contactid.next(
    //   this.stores.filter(x => x.id == this.storeid)[0].contactId,
    // )
    // this.auth.contactid.next(this.stores.filter(x => x.id == this.storeid)[0].contactId)
  }

  getStores() {
    if (this.roleId == 1 || this.roleId == 2) {
      this.auth.Lggetstore(this.companyid, null, null).subscribe(data => {
        this.stores = data
        console.log(this.stores)
        this.mergeDuplicateData()
      })
    } else if (this.roleId == 8 || this.roleId == 9 || this.roleId == 7) {
      this.auth.Lggetstore(this.companyid, null, this.userId).subscribe(data => {
        this.stores = data
        console.log(this.stores)
      })
    } else {
      console.log('Store', this.SId)
      this.auth.Lggetstore(this.companyid, this.SId, null).subscribe(data => {
        this.stores = data //[0]
        this.storeid = this.stores[0].id
        this.setstoreid()
        console.log(this.stores)
      })
    }
  }

  mergeDuplicateData() {
    const mergedStores = {}

    for (const store of this.stores) {
      const name = store.name
      if (mergedStores[name]) {
        mergedStores[name] = { ...mergedStores[name], ...store }
      } else {
        mergedStores[name] = { ...store }
      }
    }
    this.stores = Object.values(mergedStores)

    console.log(this.stores)
  }
}
