import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { AuthService } from 'src/app/auth.service'
import { ConstantsService } from '../../../../../services/constants/constants.service'
import { Router } from '@angular/router'

@Component({
  selector: 'cui-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  badgeCount: number = 0
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''
  datasavetype: string = '1'
  user: any
  CompanyId: any
  StoreId: any

  constructor(
    private store: Store<any>,
    private Auth: AuthService,
    private globals: ConstantsService,
    private router: Router,
  ) {
    //this.store.pipe(select(Reducers.getUser)).subscribe(state => {
    // this.name = state.name
    // this.role = state.role
    // this.email = state.email
    //})

    this.user = JSON.parse(localStorage.getItem('user'))
    console.log(this.user)
    this.name = this.user[0].user
    this.role = this.user[0].role
    this.email = globals.email
    this.datasavetype = localStorage.getItem('datasavetype')
  }

  badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.setItem('logState', 'logged_out')
    this.store.dispatch(new UserActions.Logout())
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'))
    const store = JSON.parse(localStorage.getItem('store'))
    this.CompanyId = user[0].companyId
    this.StoreId = user[0].storeId
  }

  lock() {
    localStorage.removeItem('user')
    console.log(localStorage.removeItem('user'))
    // this.StoreId = 0
    this.router.navigateByUrl('/auth/pinscreen')
  }
}
