<div class="topbar">
  <div class="mr-4">
    <!-- <cui-topbar-fav-pages></cui-topbar-fav-pages> -->
  </div>
  <div class="mr-auto">
    <!-- <cui-topbar-search></cui-topbar-search> -->
  </div>
  <div class="mr-4 d-none d-md-block">
    <!-- <cui-topbar-issues-history></cui-topbar-issues-history> -->
  </div>
  <div class="mb-0 mr-auto d-xl-block d-none">
    <!-- <cui-topbar-project-management></cui-topbar-project-management> -->
  </div>
  <div class="mr-4 d-none d-sm-block">
    <!-- <cui-topbar-language-switcher></cui-topbar-language-switcher> -->
  </div>

  <div class="mr-4  d-sm-block">
    <!-- <cui-topbar-actions></cui-topbar-actions> -->
    <select *ngIf="!is_user_page" class="form-control" [(ngModel)]="storeid" (change)="setstoreid()"
      style="height: 30px;">
      <!-- <option *ngIf="!is_user_page" value="0" selected disabled>Select Store</option> -->
      <option *ngIf="!is_user_page" value="0" selected>All Store</option>
      <option value="{{store.id}}" [selected]="is_user_page" *ngFor="let store of stores">{{store.name}}</option>
    </select>
    <select disabled class="form-control" *ngIf="is_user_page">
      <option [selected]="roleId == 10 && roleId == 12 &&  is_user_page" value="-1">{{savestore}}</option>
      <option *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 8 || roleId == 9" value="0"
        [selected]="is_user_page">Admin</option>
    </select>
  </div>

  <!-- <cui-topbar-actions></cui-topbar-  actions> -->

  <!-- <div *ngIf="is_user_page" class="mr-4 d-none d-sm-block">
    <select  *ngIf="showStore" class="form-control" [(ngModel)]="storeId">
      <option value="{{storeId}}" disabled>{{test1}}</option>
    </select>
  </div> -->

  <!-- <div *ngIf="is_user_page" class="mr-4 d-none d-sm-block">
    <input type="text" style="width: 130px;" *ngIf="showStore" disabled class="form-control" [(ngModel)]="stores.name">
  </div> -->

  <div>
    <cui-topbar-user-menu></cui-topbar-user-menu>
  </div>
</div>