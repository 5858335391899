//['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER', 'EMPR']
export const getMenuData: any[] = [
  // {
  //   category: true,
  //   title: 'ERP Management',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  // },
  // {
  //   title: 'Internal Transfer',
  //   key: 'apps',
  //   icon: 'fa fa-exchange',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],

  //   children: [
  //     {
  //       title: 'Receiver',
  //       key: 'apps',
  //       icon: 'fa fa-sign-in',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'STOREUSER'],
  //       children: [
  //         {
  //           title: 'Place Order',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'STOREUSER'],
  //           url: '/apps/NewToOrder',
  //         },
  //         {
  //           title: 'Order Index',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'STOREUSER'],
  //           url: '/apps/ToOrder',
  //         },
  //         {
  //           title: 'Receive Index',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'STOREUSER'],
  //           url: '/apps/ToReceive',
  //         },
  //         {
  //           title: 'ReceivedOrder Index',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'STOREUSER'],
  //           url: '/apps/ReceivedOrder',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Supplier',
  //       key: 'apps',
  //       icon: 'fe fe-truck',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //       children: [
  //         {
  //           title: 'NewDispatch',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //           url: '/apps/NewToDispatch',
  //         },
  //         {
  //           title: 'Dispatch Index',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //           url: '/apps/ToDispatch',
  //         },
  //         {
  //           title: 'DispatchedOrder Index',
  //           key: 'appsSetting',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //           url: '/apps/DispatchedOrder',
  //         },
  //         {
  //           title: 'Dispatch Print',
  //           key: 'Dispatch-Index App',
  //           url: '/apps/dispatch-index',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Stock Transfer',
  //   key: 'apps',
  //   icon: 'fa fa-stack-overflow',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  //   children: [
  //     {
  //       title: 'Inital Stock',
  //       key: 'appsInitialStock',
  //       url: '/apps/initalstock',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  //     },
  //     {
  //       title: 'Stock Reorder',
  //       key: 'appsStockReorder',
  //       url: '/apps/stockreorder',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  //     },
  //     {
  //       title: 'Stock Update',
  //       key: 'appsStockUpdate',
  //       url: '/apps/stockupdate',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  //     },
  //     {
  //       title: 'Auto Order',
  //       key: 'appsAutoOrder',
  //       url: '/apps/autoorder',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  //     },
  //   ],
  // },

  // {
  //   title: 'Purchase',
  //   key: 'apps',
  //   icon: 'fa fa-shopping-cart',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  //   url: '/apps/purchaseentry',
  // },
  // {
  //   title: 'Closing',
  //   key: 'apps',
  //   icon: 'fa fa-braille',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //   children: [
  //     {
  //       title: 'Day Closing',
  //       // icon: 'fa fa-braille',
  //       key: 'sentostore App',
  //       url: '/apps/dayclosing',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //     },
  //     {
  //       title: 'Cash Closing',
  //       // icon: 'fa fa-money',
  //       key: 'sentostore App',
  //       url: '/apps/cashclosing',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //     },
  //     {
  //       title: 'Account Closing',
  //       // icon: 'fa fa-cc-paypal',
  //       key: 'sentostore App',
  //       url: '/apps/accountclosing',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //     },
  //   ],
  // },

  // {
  //   title: 'Finance',
  //   key: 'apps',
  //   icon: 'fa fa-usd',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //   children: [
  //     {
  //       title: 'Payables',
  //       key: 'apps',
  //       icon: 'fa fa-arrow-circle-right',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //       children: [
  //         {
  //           title: 'Purchase & Maintanance',
  //           key: 'PurchaseMaintanance',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //           url: '/apps/purchmaint',
  //         },
  //         {
  //           title: 'Bill Pay By Vendor',
  //           key: 'appsBillPayByVendor',
  //           url: '/apps/billspaybyvendor',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Receivables',
  //       key: 'apps',
  //       icon: 'fa fa-arrow-circle-left',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //       children: [
  //         {
  //           title: 'Credit',
  //           key: 'appsCredit',
  //           icon: 'fa fa-credit-card',
  //           roles: ['ERPADMIN', 'ADMIN', 'FACADMIN'],
  //           url: '/apps/credit',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   title: 'Maintanance',
  //   icon: 'fa fa-wrench',
  //   key: 'maintanace App',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //   children: [
  //     {
  //       title: 'Asset Maintanance',
  //       key: 'AssetMaint',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //       url: '/apps/maintanance',
  //     },
  //     {
  //       title: 'Maintanance Type',
  //       key: 'MaintType',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  //       url: '/apps/maintbilltype',
  //     },
  //   ],
  // },

  // {
  //   title: 'Bank Account',
  //   icon: 'fa fa-bank',
  //   key: 'Bank Account App',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //   children: [
  //     {
  //       title: 'Money Transfer',
  //       key: 'moneytransfer apps',
  //       roles: ['ERPADMIN'],
  //       url: '/apps/moneytransfer',
  //     },
  //     {
  //       title: 'Deposit',
  //       key: 'deposit apps',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //       url: '/apps/deposit',
  //     },
  //     {
  //       title: 'Withdrawal',
  //       key: 'withdrawal apps',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //       url: '/apps/withdrawal',
  //     },
  //     {
  //       title: 'Add Account',
  //       key: 'add BankAccount',
  //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  //       url: '/apps/addbankaccount',
  //     },
  //   ],
  // },
  // {
  //   title: 'Send To Store',
  //   icon: 'fa fa-share-square-o',
  //   key: 'sentostore App',
  //   url: '/apps/sendtostore',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  // },
  // {
  //   category: true,
  //   title: 'Company Management',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  // },
  // {
  //   title: 'Company',
  //   key: 'company',
  //   icon: 'fa fa-building',
  //   url: '/apps/company',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  // },
  // {
  //   title: 'Location',
  //   key: 'location',
  //   icon: 'fa fa-map-marker',
  //   url: '/apps/location',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  // },
  // // {
  // //   title: 'Customer',
  // //   key: 'customer',
  // //   icon: 'fa fa-smile-o',
  // //   url: '/apps/customer',
  // //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  // // },
  // {
  //   title: 'User',
  //   key: 'user',
  //   icon: 'fa fa-user-plus',
  //   url: '/apps/user',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  // },
  // // {
  // //   title: 'Vendor',
  // //   key: 'vendor',
  // //   icon: 'fa fa-briefcase',
  // //   url: '/apps/vendor',
  // //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  // // },
  // {
  //   title: 'Payment Type',
  //   key: 'paymenttype',
  //   icon: 'fa fa-money',
  //   url: '/apps/paymenttype',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER'],
  // },
  // // {
  // //   title: 'Asset',
  // //   key: 'apps',
  // //   icon: 'fa fa-tasks',
  // //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  // //   children: [
  // //     {
  // //       title: 'Assets',
  // //       key: 'appsAsset',
  // //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  // //       url: '/apps/asset',
  // //     },
  // //     {
  // //       title: 'Asset-Types',
  // //       key: 'appsAssetType',
  // //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  // //       url: '/apps/assettype',
  // //     },
  // //     {
  // //       title: 'Online Payment',
  // //       key: 'appsSwipingMachine',
  // //       roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'FACUSER'],
  // //       url: '/apps/swiping',
  // //     },
  // //   ],
  // // },
  {
    category: true,
    title: 'Menu Management',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  },
  {
    title: 'Product',
    key: 'appsProfile',
    icon: 'fe fe-grid',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/products',
  },
  {
    title: 'Category',
    key: 'appsProfile',
    icon: 'fa fa-sitemap',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/category',
  },
  {
    title: 'TaxGroup',
    key: 'appsProfile',
    icon: 'fa fa-object-group',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/taxgroup',
  },

  {
    title: 'KotGroups',
    key: 'appsProfile',
    icon: 'fa fa-bell-o',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/KotGroups',
  },
  {
    title: 'DiningArea',
    key: 'appsProfile',
    icon: 'fa fa-table',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/DiningArea',
  },
  {
    title: 'Storepayments',
    key: 'appsStorepayments',
    icon: 'fa fa-object-ungroup',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/storepayments',
  },
  // {
  //   title: 'Options',
  //   key: 'appsSetting',
  //   icon: 'fe fe-check-square',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  //   url: '/apps/options',
  // },
  {
    title: 'StoreProduct',
    key: 'appsStoreProduct',
    icon: 'fa fa-th-large',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/StoreProduct',
  },

  {
    category: true,
    title: 'Company Management',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  },
  {
    title: 'Company',
    key: 'company',
    icon: 'fa fa-building',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/company',
  },

  {
    title: 'User',
    key: 'user',
    icon: 'fa fa-user-plus',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/user',
  },
  {
    title: 'Location',
    key: 'location',
    icon: 'fa fa-map-marker',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/location',
  },

  {
    title: 'Payment Type',
    key: 'paymenttype',
    icon: 'fa fa-money',
    roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
    url: '/apps/paymenttype',
  },
  // {
  //   title: 'ProductMapping',
  //   key: 'appsProductMapping',
  //   icon: 'fa fa-thin fa-list',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN', 'FACUSER', 'STOREUSER'],
  //   url: '/apps/productmapping',
  // },
  // {
  //   category: true,
  //   title: 'POS Management',
  //   roles: ['ERPADMIN'],
  // },
  // {
  //   category: true,
  //   title: 'HRMS',
  //   roles: ['ERPADMIN', 'ADMIN', 'EMPR'],
  // },
  // // {
  // //   title: 'Receipts',
  // //   icon: 'fa fa-file-text',
  // //   key: 'receipt App',
  // //   url: '/apps/receipt',
  // //   roles: ['ERPADMIN'],
  // // },
  // // {
  // //   title: 'Denomination',
  // //   key: 'denominations',
  // //   icon: 'fa fa-columns',
  // //   url: '/apps/denominations',
  // //   roles: ['ERPADMIN'],
  // // },
  // // {
  // //   title: 'Attendance',
  // //   icon: 'fa fa-user',
  // //   key: 'attendance App',
  // //   url: '/apps/attendance',
  // //   roles: ['ERPADMIN'],
  // // },
  // {
  //   title: 'Employee',
  //   key: 'employee',
  //   icon: 'fa fa-id-badge',
  //   url: '/apps/employee',
  //   roles: ['ERPADMIN', 'ADMIN', 'FACADMIN', 'STOREADMIN'],
  // },
  // {
  //   title: 'Attendance',
  //   key: 'attendancerptlogs',
  //   icon: 'fa fa-user-circle-o',
  //   url: '/apps/attendancerptlogs',
  //   roles: ['ERPADMIN', 'ADMIN', 'EMPR'],
  // },
]
